/* globals jsTranslations */

/**
 * Iris related headless mode
 */
export default class IrisEventHandler {
    /**
     * Iris module constructor
     *
     * @constructor
     */
    constructor() {
        // only subscribe to Messages if iris doesnt exist on the page
        if (!document.getElementById('iris')) {
            this.subscribeToIncomingMessageAndBrowserNotify();
            this.subscribeToIncomingAmountOfMessages();
        }
        this.subscribeToNewMessageAndSoundNotify();
    }

    /**
     * Subscribe to upcoming message and sound notify
     */
    subscribeToNewMessageAndSoundNotify() {
        document.addEventListener('iris-new-incoming-message', (e) => {
            if (e.detail.notification && e.detail.notification.isSoundOn) {
                Fx.Dashboard.App.ringNewMessage();
            }
        });
    }

    /**
     * Subscribe to upcoming message and browser notify
     */
    subscribeToIncomingMessageAndBrowserNotify() {
        const messageMaxLenght = 80;
        document.addEventListener('iris-new-incoming-message', (e) => {
            const conversationLink = [
                document.location.origin,
                '/dashboard/iris',
                e.detail.conversationLink,
            ].join('');
            const truncatedWord = () => {
                if (e.detail.body.length > messageMaxLenght) {
                    return e.detail.body[messageMaxLenght] !== ' ' &&
                        e.detail.body[messageMaxLenght + 1] !== ' '
                        ? e.detail.body.substring(0, messageMaxLenght) + '...'
                        : e.detail.body.substring(0, messageMaxLenght);
                }
                return e.detail.body;
            };
            const message = [e.detail.from, truncatedWord()].join(': ');

            Fx.Dashboard.App.showNotification(message, {
                timeout: false,
                forceClose: true,
                showActionButton: true,
                actionButtonText: jsTranslations.snackbarReadMessage,
                actionButtonHandler: function () {
                    window.open(conversationLink, '_blank');
                },
                browserNotificationTitle:
                    jsTranslations.browserNotificationNewMessage,
            });
        });
    }

    /**
     * Subscribe to Incoming Amount of messages
     */
    subscribeToIncomingAmountOfMessages() {
        document.addEventListener('iris-unread-messages-update', (e) => {
            const detail = e.detail;
            const counterMenuElem =
                document.getElementsByClassName('unread-messages')[0];

            if (!counterMenuElem) {
                return;
            }
            if (detail && detail.total && detail.total > 0) {
                const newMessagesCount =
                    detail.total > 99 ? '99+' : detail.total;
                counterMenuElem.textContent = newMessagesCount;
                counterMenuElem.classList.add('active');
                Fx.Dashboard.App.prependMessageToTabTitle(newMessagesCount);
            } else {
                counterMenuElem.classList.remove('active');
                Fx.Dashboard.App.prependMessageToTabTitle(undefined);
            }
        });
    }
}
